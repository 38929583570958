/*fonts*/
@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-bold-webfont.eot');
    src: url('fonts/gilroy-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/gilroy-bold-webfont.woff2') format('woff2'),
         url('fonts/gilroy-bold-webfont.woff') format('woff'),
         url('fonts/gilroy-bold-webfont.ttf') format('truetype'),
         url('fonts/gilroy-bold-webfont.svg#gilroybold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-medium-webfont.eot');
    src: url('fonts/gilroy-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/gilroy-medium-webfont.woff2') format('woff2'),
         url('fonts/gilroy-medium-webfont.woff') format('woff'),
         url('fonts/gilroy-medium-webfont.ttf') format('truetype'),
         url('fonts/gilroy-medium-webfont.svg#gilroymedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-regular-webfont.eot');
    src: url('fonts/gilroy-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/gilroy-regular-webfont.woff2') format('woff2'),
         url('fonts/gilroy-regular-webfont.woff') format('woff'),
         url('fonts/gilroy-regular-webfont.ttf') format('truetype'),
         url('fonts/gilroy-regular-webfont.svg#gilroyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-regularitalic-webfont.eot');
    src: url('fonts/gilroy-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/gilroy-regularitalic-webfont.woff2') format('woff2'),
         url('fonts/gilroy-regularitalic-webfont.woff') format('woff'),
         url('fonts/gilroy-regularitalic-webfont.ttf') format('truetype'),
         url('fonts/gilroy-regularitalic-webfont.svg#gilroyregular_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-semibold-webfont.eot');
    src: url('fonts/gilroy-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/gilroy-semibold-webfont.woff2') format('woff2'),
         url('fonts/gilroy-semibold-webfont.woff') format('woff'),
         url('fonts/gilroy-semibold-webfont.ttf') format('truetype'),
         url('fonts/gilroy-semibold-webfont.svg#gilroysemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}
/*fonts end*/
/*reset light*/
html, body {
    margin: 0;
    padding: 0;
    color: #000;
}
label, input, button, a {
    -webkit-tap-highlight-color: transparent;
}
.swiper-button-disabled {
    opacity: 0.5;
    cursor: default !important;
}
body {
    font-family: 'Gilroy', 'sans-serif', 'arial', 'helvetica';
    -webkit-font-smoothing: antialiased; 
    --c1: #FF2400;
}
svg path, svg rect, svg circle {
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}
input, textarea, select, button {
    font-family: 'Gilroy', 'sans-serif', 'arial', 'helvetica';
}
input:not([type=checkbox]):not([type=radio]),
textarea, 
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a:hover {
    text-decoration: none;
}
img, a, input, textarea, select, button {
    -webkit-border-radius: 0;
            border-radius: 0;
    outline-style: none;
}
button::-moz-focus-inner {
  border: 0;
}
    
input {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.container_main {
    min-width: 320px;
    overflow: hidden;
}
.clr {
    clear: both;
}
.align_center {
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.wmain {
    width: 1240px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.overflow {
    overflow: hidden;
}

.plr {
    padding-left: 30px;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.header {
    background: url("../img/header_bg.jpeg") no-repeat center bottom;
    -webkit-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    min-height: 100vh;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    padding-top: 320px;
    padding-bottom: 200px;
}

.header h1 {
    display: block;
    margin: 0;
    font-size: 56px;
    font-weight: bold;
    line-height: 120%;
    color: #fff;
    text-align: center;
}

.head_inf {
    text-align: center;
    margin-top: 72px;
    font-size: 32px;
    font-weight: 500;
    color: #fff;
}

.header .align_center {
    margin-top: 36px;
}

.btn_main {
    display: inline-flex;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    padding-left: 40px;
    padding-right: 40px;
    cursor: pointer;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    background-color: var(--c1);
    min-height: 56px;
    line-height: 120%;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-border-top-left-radius: 14px;
            border-top-left-radius: 14px;
            -webkit-border-bottom-right-radius: 14px;
            border-bottom-right-radius: 14px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.5s;
       -moz-transition: 0.5s;
        -ms-transition: 0.5s;
         -o-transition: 0.5s;
            transition: 0.5s;
}

.btn_main:hover {
    background-color: #D41F01;
}

.hl_top {
    position: fixed;
    padding-left: 40px;
    padding-right: 52px;
    z-index: 200;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-border-bottom-left-radius: 40px;
            border-bottom-left-radius: 40px;
    -webkit-border-bottom-right-radius: 40px;
            border-bottom-right-radius: 40px;
    background: #fff;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    display: flex;
    height: 120px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.logo {
    display: block;
    width: 151px;
}

.hl_l {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.hl_city {
    display: flex;
    margin-left: 33px;
    font-size: 18px;
    line-height: 120%;
    -webkit-align-items: center;
            align-items: center;
}

.hl_city img {
    display: block;
    margin-right: 2px;
}

.hl_nav {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-align-items: center;
            align-items: center;
}

.hl_nav a {
    display: block;
    text-decoration: none;
    font-size: 18px;
    color: #000;
    margin-left: 10px;
    margin-right: 10px;
}

.hl_nav a:hover {
    text-decoration: underline;
}

.hl_r {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.hl_phone {
    display: block;
    text-decoration: none;
    font-size: 18px;
    color: #000;
    margin-right: 38px;
}

.hl_r .btn_main {
    min-height: 40px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 18px;
}

.get_present {
    position: relative;
    padding-top: 36px;
    padding-bottom: 87px;
}

.get_present .wmain {
    position: relative;
    z-index: 40;
}

.get_present:before {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 567px;
    left: 0;
    top: 0;
    z-index: 4;
    background: url("../img/volna_decor.svg") no-repeat center 101px;
}

.tm {
    text-align: center;
    font-size: 36px;
    line-height: 120%;
    font-weight: bold;
    color: #000;
}

.get_present_form {
    display: block;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 24px;
    background-color: #F6F6F6;
    -webkit-border-radius: 40px;
            border-radius: 40px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.form_line {
    display: flex;
    width: 885px;
    margin: 0 auto;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-justify-content: center;
            justify-content: center;
}

.form_line .btn_main {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 239px;
}

.form_line input {
    margin-right: 24px;
    width: calc(100% - 24px);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.in_style {
    display: block;
    height: 58px;
    border: none;
    padding-left: 33px;
    padding-right: 20px;
    font-size: 16px;
    color: #B2B4B3;
    -webkit-box-shadow: inset 0px 0px 10px rgba(0,0,0,0.2);
            box-shadow: inset 0px 0px 10px rgba(0,0,0,0.2);
    -webkit-border-radius: 14px;
            border-radius: 14px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

    .in_style:focus {
        color: #000;
    }

.check {
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
        text-align: left;
    margin-top: 25px;
    cursor: pointer;
    -webkit-justify-content: center;
            justify-content: center;
}

.check_inner {
    position: relative;
    padding-left: 27px;
}

.check_inner span {
    font-size: 12px;
    line-height: 15px;
    color: #898989;
    display: block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.check_inner span a {
    color: #898989;
}

.check_inner span:before {
    content: "";
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    left: 0;
    top: 50%;
    margin-top: -8px;
    border: 1px solid #898989;
    -webkit-border-radius: 2px;
            border-radius: 2px;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.check_inner span:after {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: url("../img/check_ico.svg") no-repeat left top;
    left: 0;
    top: 50%;
    margin-top: -8px;
    z-index: 10;
    opacity: 0;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.check_inner input:checked + span:before {
    opacity: 0;
}

.check_inner input:checked + span:after {
    opacity: 1;
}

.check input {
    visibility: hidden;
    opacity: 0;
    display: none;
}

.preim {
    position: relative;
    z-index: 50;
    padding-top: 55px;
    padding-bottom: 99px;
}

.preim_slider {
    position: relative;
    z-index: 20;
}

.preim_slider_main:before {
    content: "";
    display: block;
    position: absolute;
    background: url("../img/preim_decor.png");
    width: 283px;
    height: 298px;
    left: -75px;
    top: -223px;
}

.preim_slider_main {
    margin-top: 59px;
    position: relative;
}

.preim_slider_nav {
    display: flex;
    display: none;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.preim_slider_nav div + div {
    margin-left: 20px;
}

.preim_slide {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.preim_slide_l, .preim_slide_r {
    width: calc(50% - 5px);
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
}

.preim_slide_l_bottom {
    position: relative;
}

.preim_slide_l_top {
    position: relative;
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
    height: 357px;
    -webkit-border-bottom-right-radius: 60px;
            border-bottom-right-radius: 60px;
}

.preim_slide_l_top span, .preim_slide_l_bottom span, .preim_slide_r span {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 39px;
    padding-left: 16px;
    color: #fff;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
    font-weight: bold;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.preim_slide_l_bottom {
    height: 169px;
    background-color: #FF2400;
    margin-top: 12px;
    -webkit-border-top-right-radius: 60px;
            border-top-right-radius: 60px;
}

.preim_slide_l_bottom span {
    bottom: 34px;
}

.preim_slide_r {
    position: relative;
    -webkit-border-bottom-left-radius: 60px;
            border-bottom-left-radius: 60px;
}

.preim_slide_r span {
    bottom: auto;
    top: 22px;
    text-align: right;
    padding-left: 10px;
    padding-right: 16px;
}

.ps_2 .preim_slide_l_bottom {
    margin-top: 0;
}

.ps_2 .preim_slide_l .preim_slide_l_bottom + .preim_slide_l_top {
    margin-top: 12px;
    -webkit-border-radius: 0;
            border-radius: 0;
    -webkit-border-top-right-radius: 60px;
            border-top-right-radius: 60px;
}

.ps_2 > .preim_slide_l .preim_slide_l_bottom {
    -webkit-border-radius: 0;
            border-radius: 0;
        -webkit-border-bottom-right-radius: 60px;
                border-bottom-right-radius: 60px;
}

.ps_2 .preim_slide_l .preim_slide_l_bottom + .preim_slide_l_top span {
    bottom: auto;
    top: 40px;
}

.ps_2 > .preim_slide_l .preim_slide_l_bottom span {
    bottom: auto;
    top: 22px;
}

.ps_2 .preim_slide_r .preim_slide_l_top {
    -webkit-border-radius: 0;
            border-radius: 0;
    -webkit-border-bottom-left-radius: 60px;
            border-bottom-left-radius: 60px;
}

.ps_2 .preim_slide_r .preim_slide_l_top span {
    top: auto;
    bottom: 37px;
    text-align: right;
}


.ps_2 .preim_slide_r .preim_slide_l_bottom {
    -webkit-border-radius: 0;
            border-radius: 0;
    margin-top: 12px;
    -webkit-border-top-left-radius: 60px;
            border-top-left-radius: 60px;
}

.ps_2 .preim_slide_r .preim_slide_l_bottom span {
    top: auto;
    bottom: 28px;
    text-align: right;
}

.ps_3 .preim_slide_r {
    -webkit-border-radius: 0;
            border-radius: 0;
    -webkit-border-bottom-right-radius: 60px;
            border-bottom-right-radius: 60px;
}

.ps_3 .preim_slide_r span {
    bottom: auto;
    left: 0;
    top: 22px;
    text-align: left;
    padding-left: 22px;
    padding-right: 10px;
}

.ps_3 .preim_slide_l_bottom {
    margin-top: 0;
    -webkit-border-radius: 0;
            border-radius: 0;
    -webkit-border-bottom-left-radius: 60px;
            border-bottom-left-radius: 60px;
}

.ps_3 .preim_slide_l_bottom span {
    text-align: right;
    bottom: auto;
    top: 22px;
    padding-left: 10px;
    padding-right: 15px;
}

.ps_3 .preim_slide_l_top {
    margin-top: 12px;
    -webkit-border-radius: 0;
            border-radius: 0;
    -webkit-border-top-left-radius: 60px;
            border-top-left-radius: 60px;
}

.ps_3 .preim_slide_l_top span {
    bottom: auto;
    top: 30px;
    padding-left: 10px;
    padding-right: 15px;
    text-align: right;
}

.plan {
    background-color: #F6F6F6;
    padding-top: 20px;
    padding-bottom: 30px;
}

.plan .tm {
    position: relative;
    z-index: 20;
    text-shadow: 1px 1px 0px #F6F6F6;
}

.plan .wmain {
    position: relative;
}

    .plan .wmain:before {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/plan_decor.png");
        width: 377px;
        height: 377px;
        right: -125px;
        top: -110px;
    }

.plan_slider {
    overflow: hidden;
}

.plan_slider_nav {
    display: flex;
    margin-top: 30px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.plan_slider_nav div + div {
    margin-left: 30px;
}

.plan_nav {
    display: flex;
    position: relative;
    z-index: 20;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: -10px;
    -webkit-justify-content: center;
            justify-content: center;
}

.plan_nav div {
    cursor: pointer;
    text-shadow: 1px 1px 0px #F6F6F6;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 120%;
    padding-top: 2px;
    padding-bottom: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 1px solid transparent;
    -webkit-border-radius: 10px;
            border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.plan_nav div:hover, .plan_nav div.act {
    border: 1px solid #FF2400;
}

.plan_nav div.act {
    font-weight: 500;
}

.plan_slider {
    padding-top: 36px;
    padding-bottom: 10px;
    position: relative;
    z-index: 20;
}

.plan_slide {
    -webkit-border-radius: 20px 40px;
            border-radius: 20px 40px;
    /* -webkit-box-shadow: 0px 10px 15px rgba(0,0,0,0.1); */
            /* box-shadow: 0px 10px 15px rgba(0,0,0,0.1); */
    background: #fff;
    padding: 20px;
    cursor: pointer;
    top: 0;
    position: relative;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .plan_slide:hover {
        top: -5px;
    }

.plan_slide_img {
    height: 206px;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.plan_slide_img img {
    display: block;
    max-width: 100%;
    max-height: 206px;
}

.plan_slide_name {
    text-align: center;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
}

.plan_slide .align_center {
    margin-top: 16px;
}

.plan_slide .btn_main {
    min-height: 40px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
}

.shema {
    padding-top: 40px;
    padding-bottom: 33px;
    overflow: hidden;
    position: relative;
}

    .shema:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 100px;
        top: 17px;
        background: url("../img/map_volna.svg") no-repeat center top;
    }

.shema .wmain {
    position: relative;
    z-index: 30;
}

    .shema .wmain:before {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/map_decor.png");
        width: 363px;
        height: 268px;
        left: -230px;
        top: -15px;
    }

.shema_bottom_text {
    text-align: center;
    margin-top: 19px;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
}

.shema_content {
    position: relative;
    z-index: 40;
    overflow: hidden;
    margin-top: 19px;
    height: 560px;
    -webkit-border-radius: 40px;
            border-radius: 40px;
}

.shema_content iframe {
    height: 100% !important;
}

.shema_content > img {
    display: block;
    position: absolute;
    width: 1240px;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
}

.otdelka {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;
}

    .otdelka:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url("../img/otdelka_volna.svg") no-repeat center 50px;
    }

.otdelka .wmain {
    position: relative;
    z-index: 20;
}

.otdelka_list {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 37px;
}

.otdelka_list > li {
    cursor: pointer;
    width: calc(33.3333% - 10px);
    height: 398px;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 40px;
            border-radius: 40px;
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
}

.otdelka_list > li:before {
    content: "";
    display: block;
    position: absolute;
    width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, #fff, rgba(255,255,255,0));
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.otdelka_list > li:hover:before {
    width: 100%;
}

.ol_title {
    position: absolute;
    z-index: 10;
    left: 39px;
    top: 47px;
    font-size: 28px;
    line-height: 120%;
    font-weight: bold;
    padding-bottom: 10px;
}

    .ol_title:before {
        content: "";
        display: block;
        position: absolute;
        width: 130px;
        height: 2px;
        left: 0;
        bottom: 0;
        background: #000;
    }

.ol_btn {
    position: absolute;
    z-index: 20;
    left: 27px;
    bottom: 30px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
}

    .ol_btn:before {
        content: "";
        display: block;
        position: absolute;
        width: 19px;
        height: 13px;
        background: url("../img/otdelka_str.svg");
        right: -34px;
        bottom: 6px;
        -webkit-transition: 0.5s;
           -moz-transition: 0.5s;
            -ms-transition: 0.5s;
             -o-transition: 0.5s;
                transition: 0.5s;
    }


.otdelka_list > li:hover .ol_btn:before {
    right: -40px;
}

.buy_list {
    padding-bottom: 61px;
}

.buy_list > li {
    position: relative;
    overflow: visible;
}

.buy_list > li .align_center {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -61px;
}

.buy_list > li .align_center .btn_main {
    min-height: 40px;
    padding-left: 33px;
    padding-right: 33px;
}

.hod {
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
}

.hod:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 90px;
    top: -50px;
    background: url("../img/otdelka_volna.svg") no-repeat center top;
}

.hod .wmain {
    position: relative;
    z-index: 40;
}

.hod .preim_slider_nav {
    display: flex;
    margin-top: 40px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.hod_slider, .hod_slider .swiper-wrapper {
    overflow: visible !important;
}

.hod_slider .swiper-wrapper {
    justify-content: center;
}

.hod_slider {
    margin-top: 35px;
}

.hod_slide {
    display: block;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    -webkit-border-radius: 40px;
            border-radius: 40px;
}

.hod_slide img {
    display: block;
    width: 100%;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.hod_slide img:hover {
    -webkit-transform: scale(1.05);
       -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
         -o-transform: scale(1.05);
            transform: scale(1.05);
}

.hod_slider .swiper-slide {
    width: 340px;
}

.hod_nav {
    display: flex;
    margin-top: 16px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.hod_mounth, .hod_year {
    display: flex;
    margin-bottom: -10px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.hod_year {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.hod_mounth > div, .hod_year > div {
    cursor: pointer;
    font-size: 20px;
    line-height: 120%;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 2px;
    margin-right: 2px;
    height: 26px;
    line-height: 22px;
    margin-bottom: 10px;
    -webkit-border-radius: 10px;
            border-radius: 10px;
    border: 1px solid transparent;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.hod_mounth > div.act, .hod_year > div.act, .hod_mounth > div:hover, .hod_year > div:hover {
    border-color: #FF2400;
    font-weight: 600;
}

.hod_year {
    margin-left: 30px;
}

.about {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #F6F6F6;
}

.about_inner {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40px;
    background: #fff;
    padding: 58px 97px 62px 35px;
    -webkit-border-radius: 40px;
            border-radius: 40px;
}

.about_l {
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.about_l img {
    display: block;
    max-width: 100%;
}

.about_l .btn_main {
    white-space: nowrap;
    padding-left: 72px;
    padding-right: 72px;
    margin-top: 28px;
    font-size: 20px;
    font-weight: 600;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.about_r {
    padding-left: 77px;
}

.about_r p {
    display: block;
    margin: 0;
    font-size: 20px;
    line-height: 120%;
    color: #000;
}

    .about_r p a {
        color: #000;
    }

.about_r p + p {
    margin-top: 10px;
}

.contact {
    position: relative;
    height: 625px;
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
}

.contact_inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 75px;
}

.contact_inner .wmain {
    height: 0;
}

.content_plashka {
    position: relative;
    width: 436px;
    min-height: 470px;
    background-color: #FFF;
    padding: 40px;
    padding-bottom: 110px;
    -webkit-border-radius: 30px;
            border-radius: 30px;
    -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.cp__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 120%;
}

.cp__list {
    margin-top: 24px;
    font-size: 24px;
    line-height: 120%;
}

.cp__list > div + div {
    margin-top: 18px;
}

.content_plashka .btn_main {
    position: absolute;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    left: 40px;
    right: 40px;
    bottom: 40px;
    font-size: 20px;
    font-weight: 600;
}

.footer {
    background-color: #383838;
    padding-top: 16px;
    padding-bottom: 16px;
}

.footer .wmain {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
    color: #FFF;
}


.footer .wmain a {
    color: #FFF;
}

.f_left {
    display: flex;
}

.fl__l {
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
    color: #FFF;
}

.fl__l > div img {
    display: block;
    max-width: 158px;
    margin-top: 12px;
}

.fl__l > div a {
    text-decoration: none;
}

.fl__l > div + div {
    margin-top: 12px;
}

.fl__l > div a:hover {
    text-decoration: underline;
}

.fl__r {
    margin-left: 67px;
    min-width: 247px;
}

.f_nav a {
    display: block;
    text-decoration: none;
    white-space: nowrap;
}

.f_nav a:hover {
    text-decoration: underline;
}

.f_nav a + a {
    margin-top: 8px;
}

.f_right {
    padding-left: 68px;
    max-width: 487px;
}

.pu_rgba {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 900;
    background-color: rgba(0,0,0,0.6);
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.pu_table {
    display: table;
    width: 100%;
    vertical-align: middle;
    height: 100%;
}

.pu_cell {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}

.pu_inner {
    position: relative;
    max-width: 664px;
    background-color: #FFF;
    -webkit-border-radius: 60px;
            border-radius: 60px;
    -webkit-background-size: cover !important;
         -o-background-size: cover !important;
            background-size: cover !important;
    background-position: center top !important;
    margin: 0 auto;
    padding: 48px 115px 59px 115px;
    min-height: 528px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.closeform {
    cursor: pointer;
    position: absolute;
    right: -60px;
    top: -14px;
    -webkit-transition: 0.2s;
       -moz-transition: 0.2s;
        -ms-transition: 0.2s;
         -o-transition: 0.2s;
            transition: 0.2s;
}

    .closeform:hover {
        -webkit-transform: rotate(90deg);
           -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
             -o-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .closeform svg {
        display: block;
    }

.pu_title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    line-height: 120%;
    text-shadow: 1px 1px 0px #fff;
}

.pu_form {
    display: block;
    margin-top: 47px;
    background-color: rgba(246,246,246,0.7);
    padding: 24px 64px 24px 64px;
    -webkit-box-shadow: 0px 6px 12px rgba(0,0,0,0.11);
            box-shadow: 0px 6px 12px rgba(0,0,0,0.11);
    -webkit-border-radius: 40px;
            border-radius: 40px;
}

.pu_form input {
    width: 100%;
}

.pu_form input + input {
    margin-top: 24px;
}

.pu_form .btn_main {
    width: 100%;
    margin-top: 24px;
    padding-left: 0;
    padding-right: 0;
    font-size: 20px;
    font-weight: 600;
}

.pu_form .check {
    max-width: 483px;
    margin: 0 auto;
    margin-top: 24px;
}

.pu_doc {
    padding-left: 89px;
    padding-right: 89px;
}

.pu_doc .pu_form {
    padding-bottom: 32px;
    padding-top: 32px;
}

.pu_flat {
    max-width: 822px;
    padding: 48px 64px 45px 64px;
    padding-left: 30px;
}

.pu_flat_inner {
    display: flex;
    margin-top: 18px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pu_flat_img {
    width: 330px;
    min-width: 330px;
    cursor: pointer;
}

    .pu_flat_img:hover img {
        transform: scale(1.03);
    }

.pu_flat_img img {
    display: block;
    max-width: 100%;
    transition: 0.3s;
}

.pu_flat .pu_form {
    width: auto;
    margin-left: 20px;
    padding: 32px 24px;
}

.pu_flat_info {
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    padding-bottom: 16px;
}

.pu_flat_info div + div {
    margin-top: 12px;
}

.pu_ipoteka {
    max-width: 822px;
    padding: 48px 74px 86px 74px;
}

.pu_ipoteka .pu_form {
    padding: 32px 24px 34px 24px;
}

.select_style {
    display: block;
    width: 100%;
    position: relative;
    cursor: pointer;
}

    .select_style:before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        pointer-events: none;
        height: 100%;
        right: 25px;
        top: 0;
        z-index: 10;
        background: url("../img/select_str.svg") no-repeat center center;
    }

.select_style select {
    display: block;
    width: 100%;
    height: 56px;
    height: 58px;
    border: none;
    cursor: pointer;
    padding-right: 50px;
    padding-left: 33px;
    padding-right: 20px;
    font-size: 16px;
    color: #000;
    -webkit-box-shadow: inset 0px 0px 10px rgba(0,0,0,0.2);
            box-shadow: inset 0px 0px 10px rgba(0,0,0,0.2);
    -webkit-border-radius: 14px;
            border-radius: 14px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.pu_form_sort {
    display: flex;
    margin-bottom: -24px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.pu_form_sort .select_style, .pu_form_sort .in_style {
    margin-bottom: 24px;
    width: calc(50% - 8px);
}

.pu_form_sort .select_style {
    margin-right: 16px;
}

.hl_btn_nav {
    display: none;
    width: 15px;
    height: 12px;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    left: 370px;
}

.hl_btn_nav:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    left: -20px;
    top: -20px;
}

.hl_btn_nav div {
    width: 100%;
    height: 2px;
    position: relative;
    top: 0;
    left: 0;
    background-color: #000;
    -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
        -ms-transition: 0.3s;
         -o-transition: 0.3s;
            transition: 0.3s;
}

.hl_btn_nav div + div {
    margin-top: 4px;
}


.hl_btn_nav div:nth-child(2) {
    width: 11px;
    margin-left: 4px;
}

.hl_top_open .hl_btn_nav div:nth-child(2) {
    top: 20px;
    opacity: 0;
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

.hl_top_open .hl_btn_nav div:nth-child(1) {
    top: 6px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}
.hl_top_open .hl_btn_nav div:nth-child(3) {
    top: -6px;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header h1 br {
    /* display: none; */
}

.shema .tm {
    position: relative;
    z-index: 20;
}

.hl_top_open .hl_nav {
    display: block;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-border-bottom-left-radius: 20px;
            border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
            border-bottom-right-radius: 20px;
}

.hl_top_open {
    -webkit-border-radius: 0 !important;
            border-radius: 0 !important;
}

.lg-item, .lg-toolbar {
    background-color: #fff !important;
}


/*@media*/
@media screen and (max-width: 1380px) {
    .hl_top {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hl_r {
        display: block;
    }
    .hl_phone {
        margin-right: 0;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 1320px) {
    .contact_inner .wmain {
        padding-left: 20px;
    }
    .wmain {
        width: 100%;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
}

@media screen and (max-width: 1200px) {
    .hl_btn_nav {
        display: block;
    }
    .hl_nav {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        background-color: #FFF;
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .hl_nav > a + a {
        margin-top: 15px;
    }

    .hl_r .btn_main {
        min-height: 20px;
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .hl_phone {
        padding-bottom: 5px;
        font-size: 16px;
    }
    .hl_top {
        height: 70px;
        -webkit-border-bottom-left-radius: 20px;
                border-bottom-left-radius: 20px;
        -webkit-border-bottom-right-radius: 20px;
                border-bottom-right-radius: 20px;
    }

    .header {
        padding-top: 200px;
        padding-bottom: 130px;
    }
    .head_inf {
        margin-top: 42px;
    }
}

@media screen and (max-width: 1140px) {
    .about_inner {
        padding-right: 30px;
    }
    .about_r {
        padding-left: 30px;
    }
    .plan .wmain:before {
        width: 200px;
        height: 150px;
        right: -40px;
        top: 15px;
        -webkit-background-size: 200px;
             -o-background-size: 200px;
                background-size: 200px;
    }
    .preim_slider .preim_slider_nav {
        display: flex;
        margin-top: 32px;
    }
}

@media screen and (max-width: 1080px) {
    .hod_slider .swiper-wrapper {
        justify-content: flex-start;
    }
    .contact #map {
        left: 30%;
        transform: translateX(-50%);
        position: relative;
        width: 1800px !important;
    }
}


@media screen and (max-width: 1020px) {
    .f_right {
        padding-left: 0;
    }
    .fl__r {
        margin-left: 27px;
        min-width: 197px;
    }
    .content_plashka .btn_main {
        font-size: 16px;
        min-height: 40px;
    }
    .content_plashka {
        padding: 20px;
        padding-bottom: 40px;
    }
    .cp__title {
        font-size: 24px;
    }
    .cp__list {
        font-size: 20px;
    }
    .content_plashka {
        width: 346px;
    }
    .form_line {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .closeform {
        right: 0;
        top: -30px;
    }
}

@media screen and (max-width: 860px) {
    .pu_ipoteka, .pu_flat {
        padding-left: 30px;
        padding-right: 30px;
    }
    .ol_title {
        font-size: 20px;
    }
    .ol_btn {
        font-size: 16px;
    }
    .otdelka_list > li {
        height: 280px;
    }
}

@media screen and (max-width: 820px) {
    .about_inner {
        display: block;
    }
    .about_r {
        padding-left: 0;
        margin-top: 30px;
    }
}

@media screen and (max-width: 760px) {
    .contact_inner {
        top: auto;
        height: auto;
        bottom: 0;
    }
    .contact_inner .wmain {
        height: auto;
    }
    .contact #map {
        left: 10%;
    }
    .contact {
        height: 820px;
    }
    .pu_flat_inner {
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .pu_flat .pu_form {
        margin-left: 0;
    }
    .pu_flat .pu_form {
        margin-top: 20px;
    }
    .contact_inner {
        padding-bottom: 43px;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .content_plashka {
        width: calc(100% - 20px);
        height: auto;
        min-height: 20px;
        padding-bottom: 100px;
    }
    .contact {
        height: 720px;
    }
    .form_line {
        display: block;
    }

    .form_line input {
        margin-bottom: 24px;
        width: 100%;
    }

    .form_line .btn_main {
        width: 100%;
        min-width: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .get_present_form {
        background-color: rgba(238,238,238,0.4);
        -webkit-box-shadow: 0px 6px 12px rgba(0,0,0,0.11);
                box-shadow: 0px 6px 12px rgba(0,0,0,0.11);
    }

    .get_present_form .check {
        margin-top: 32px;
    }
}

@media screen and (max-width: 720px) {
    .pu_inner {
        padding-left: 30px;
        padding-right: 30px;
    }
    .header h1 {
        font-size: 7.5vw;
    }

    .head_inf {
        font-size: 6vw;
    }

    .header {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .header h1 br {
        display: block;
    }
}

@media screen and (max-width: 660px) {
    .preim_slider_main:before {
        width: 150px;
        height: 150px;
        left: -55px;
        top: -90px;
        -webkit-background-size: 150px;
             -o-background-size: 150px;
                background-size: 150px;
    }

    .preim_slider_main {
        margin-top: 21px;
    }

    .plr {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (max-width: 640px) {
    .pu_form {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
    }
    .pu_inner {
        min-height: 20px;
    }
    .pu_title {
        font-size: 22px;
    }
    .pu_ipoteka .pu_form {
        margin-top: 20px;
        padding: 20px;
    }
    .pu_form_sort .select_style, .pu_form_sort .in_style {
        width: 100%;
    }
    .pu_form_sort .select_style {
        margin-right: 0;
    }
    .footer .wmain {
        display: block;
    }
    .f_right {
        margin-top: 30px;
    }
    .otdelka_list {
        display: block;
    }

    .otdelka_list > li {
        width: 100%;
    }

    .otdelka_list > li + li {
        margin-top: 20px;
    }
    .buy_list > li .align_center {
        bottom: 20px;
    }
    .buy_list > li {
        background-position: right bottom !important;
    }
    .plan .wmain:before {
        top: 110px;
        width: 120px;
        height: 90px;
        -webkit-background-size: 120px;
             -o-background-size: 120px;
                background-size: 120px;
    }
}

@media screen and (max-width: 580px) {
    .shema .wmain:before {
        display: none;
    }
    .shema_content {
        height: 395px;
        max-height: 80vh;
        -webkit-border-radius: 40px;
                border-radius: 40px;
        background-color: #eee;
    }
    .swiper-button-prev svg, .swiper-button-next svg {
        display: block;
        width: 45px;
    }
    .swiper-button-prev, .swiper-button-next {
        width: auto !important;
        height: auto !important;
    }
    .plan_slider_nav {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .header {
        background: url("../img/header_bg_mobile.jpeg") no-repeat center top;
        -webkit-background-size: cover !important;
             -o-background-size: cover !important;
                background-size: cover !important;
    }
    .hl_city {
        margin-left: 5px;
    }
    .logo {
        width: 70px;
    }
    .hl_btn_nav {
        left: 240px;
    }
}

@media screen and (max-width: 520px) {
    .pu_form .btn_main {
        font-size: 16px;
        min-height: 40px;
    }
    .pu_doc_list .btn_main {
        margin-top: 0;
    }

    .pu_doc_list .btn_main + .btn_main {
        margin-top: 15px;
    }
    .hod_slider .swiper-slide {
        width: 267px !important;
    }
    .hod_nav {
        display: block;
    }
    .hod_year {
        margin-left: 0;
        margin-top: 20px;
    }
    .hod_slider {
        margin-top: 20px;
    }
    .shema_bottom_text {
        font-size: 16px;
    }
    .plan .wmain:before {
        top: 40px;
    }
    .tm {
        font-size: 20px;
    }

    .get_present_form, .about_inner, .otdelka_list, .shema_content {
        margin-top: 21px;
    }

    .plan_slider {
        padding-top: 21px;
    }

    .get_present, .preim, .plan, .shema, .otdelka, .hod, .about {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 480px) {
    .shema {
        padding-left: 0;
        padding-right: 0;
    }
    .shema_content {
        border-radius: 0;
    }
    .about_inner {
        position: relative;
        padding-bottom: 120px;
    }
    .about_l .btn_main {
        position: absolute;
        width: auto;
        left: 20px;
        right: 20px;
        bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .about_inner {
        padding: 24px 17px;
        padding-bottom: 120px;
        -webkit-border-radius: 40px;
                border-radius: 40px;
    }
    .about_r {
        margin-top: 15px;
    }
    .about_l img {
        max-width: 155px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 460px) {
    .about_r p {
        font-size: 12px;
        text-align: center;
    }

    .about_inner {
        padding-bottom: 83px;
    }

    .about_l .btn_main {
        bottom: 23px;
        left: 41px;
        right: 41px;
        font-size: 16px;
        min-height: 40px;
    }
    .plan_slider {
        margin-right: 15px;
    }
    .plan_nav div {
        font-size: 10px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
    .plan .wmain:before {
        top: 0;
    }
    .preim_slide_l_top span, .preim_slide_l_bottom span, .preim_slide_r span {
        font-size: 12px;
    }
    .preim_slide_l_top {
        height: 194px;
    }
    .preim_slide_l, .preim_slide_r {
            width: calc(50% - 4px);
    }
    .preim_slide_l_bottom {
        height: 91px;
    }
    .preim_slide_l_top span, .preim_slide_l_bottom span, .preim_slide_r span {
        padding-left: 8px;
    }
    .preim_slide_r span {
        padding-right: 8px;
    }
    .preim_slide_l_top span, .preim_slide_l_bottom span, .preim_slide_r span, .ps_2 .preim_slide_r .preim_slide_l_top span {
        bottom: 24px;
    }
    .ps_2 > .preim_slide_l .preim_slide_l_bottom span, .ps_2 .preim_slide_l .preim_slide_l_bottom + .preim_slide_l_top span {
        top: 24px;
    }
    .ps_3 .preim_slide_r span {
        padding-left: 8px;
    }
    .ps_3 .preim_slide_l_top span {
        padding-right: 8px;
    }
    .preim_slide_l_bottom {
        -webkit-border-top-right-radius: 40px;
                border-top-right-radius: 40px;
    }
    .preim_slide_r {
        -webkit-border-bottom-left-radius: 40px;
                border-bottom-left-radius: 40px;
    }
    .ps_2 .preim_slide_l .preim_slide_l_bottom + .preim_slide_l_top {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-top-right-radius: 40px;
                border-top-right-radius: 40px;
    }
    .ps_2 > .preim_slide_l .preim_slide_l_bottom {
        -webkit-border-radius: 0;
                border-radius: 0;
            -webkit-border-bottom-right-radius: 40px;
                    border-bottom-right-radius: 40px;
    }
    .ps_2 .preim_slide_r .preim_slide_l_top {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-bottom-left-radius: 40px;
                border-bottom-left-radius: 40px;
    }
    .ps_2 .preim_slide_r .preim_slide_l_bottom {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-top-left-radius: 40px;
                border-top-left-radius: 40px;
    }
    .ps_3 .preim_slide_r {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-bottom-right-radius: 40px;
                border-bottom-right-radius: 40px;
    }
    .ps_3 .preim_slide_l_bottom {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-bottom-left-radius: 40px;
                border-bottom-left-radius: 40px;
    }
    .ps_3 .preim_slide_l_top {
        -webkit-border-radius: 0;
                border-radius: 0;
        -webkit-border-top-left-radius: 40px;
                border-top-left-radius: 40px;
    }
    .head_inf {
        margin-top: 18px;
    }
    .header .align_center {
        margin-top: 81px;
    }
    .hl_city {
        display: none;
    }
    .hl_btn_nav {
        left: 100px;
        margin-top: -10px;
    }

    .btn_main {
        -webkit-border-top-left-radius: 14px;
                border-top-left-radius: 14px;
        -webkit-border-bottom-right-radius: 14px;
                border-bottom-right-radius: 14px;
        min-height: 44px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .get_present_form {
        padding: 44px 24px 30px 24px;
    }
}

@media screen and (max-width: 420px) {
    .f_right {
        text-align: center;
        margin-top: 20px;
    }
    .f_nav {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -7px;
    }
    .f_nav > a {
        width: 50%;
        box-sizing: border-box;
        white-space: wrap;
        margin-bottom: 7px;
        padding-right: 5px;
    }
    .f_nav > a + a {
        margin-top: 0;
    }
    .f_left {
        display: block;
    }
    .fl__r {
        margin-left: 0;
        width: auto;
        min-width: 20px;
        margin-top: 20px;
    }

    .footer .wmain {
        position: relative;
        padding-bottom: 100px;
    }

    .f_reate {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 110px;
    }
    .fl__l > div:nth-child(1) {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .fl__r {
        margin-top: 15px;
    }
    .f_reate {
        margin-top: 0 !important;
    }
    .fl__l > div img {
        max-width: 110px;
        margin-top: 8px;
        margin-left: -2px;
    }
    .footer .wmain {
        padding-bottom: 80px;
    }

    .pu_flat {
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 24px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .pu_flat .pu_form {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
    }
    .pu_form .check {
        margin-top: 15px;
    }
    .pu_form .btn_main {
        margin-top: 10px;
    }
    .pu_form input + input {
        margin-top: 10px;
    }
    .pu_form input {
        height: 40px;
        padding-left: 15px;
        border-radius: 10px;
        font-size: 16px;
    }
    .pu_flat_img {
        width: 100%;
        min-width: 20px;
    }
}

@media screen and (max-width: 380px) {
    .pu_flat {
        padding-left: 15px;
        padding-right: 15px;
    }
    .contact #map {
        left: -20%;
    }
    .content_plashka .btn_main {
        bottom: 20px;
    }
    .content_plashka {
        padding-bottom: 80px !important;
    }
    .contact {
        height: 840px;
    }
    .pu_doc_list .btn_main {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .pu_inner {
        -webkit-border-radius: 30px;
                border-radius: 30px;
    }
    .pu_inner {
        padding-bottom: 30px;
    }
    .pu_form {
        -webkit-border-radius: 30px;
                border-radius: 30px;
    }
    .select_style select, .in_style {
        font-size: 14px;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        -webkit-border-radius: 10px;
                border-radius: 10px;
    }
    .pu_form input + input {
        margin-top: 10px;
    }
    .pu_form .btn_main {
        margin-top: 10px;
    }
    .pu_doc .pu_form {
        padding-top: 15px;
    }
    .pu_form_sort .select_style, .pu_form_sort .in_style {
        margin-bottom: 10px;
    }
    .pu_form_sort {
        margin-bottom: -10px;
    }
    .pu_flat_info {
        font-size: 16px;
    }
    .pu_flat_img {
        width: 100%;
        min-width: 20px;
    }
    .cp__title {
        font-size: 20px;
    }
    .content_plashka {
        padding: 40px;
        padding-bottom: 156px;
    }
    .cp__list {
        font-size: 16px;
    }
    .pu_flat .pu_form {
        margin-top: 10px;
    }
}

@media screen and (max-width: 360px) {
    .pu_title {
        font-size: 18px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .content_plashka .btn_main {
        left: 20px;
        right: 20px;
    }
}